import c from "classnames";

import { InputFieldProps } from "@/types/inputFields";

import Styles from "./input.module.scss";
import { useInput } from "./useInput";
import { InputQuantityControl } from "./InputQuantityControl";

import { Label } from "../Label";
import { Icon } from "../Icon";

export const Input = ({
	type = "text",
	label,
	subLabel,
	labelType = "normal",
	name,
	id,
	register,
	validate,
	showErrors = true,
	errors,
	placeholder,
	extraText,
	errorMessages,
	value,
	onChange,
	inputWidth,
	step,
	addressee,
	className,
	track = false,
	increment,
	inputClassName = "",
	invalid = false,
	leftAlign = false,
	afterIcon,
	beforeIcon,
	onBlur,
	hasControls = true,
	hideIcon = false,
	floatingErrors = false,
	labelClassName = "",
	errorClassName = "",
	...htmlInputProps
}: InputFieldProps) => {
	const {
		focussed,
		onFocus,
		onBlurCallback,
		onQuantityIncreaseClick,
		onQuantityDecreaseClick,
		hasNumberControls,
		errorMessage,
		wrapperRef,
		revealPassword,
		toggleShowHidePassword,
	} = useInput({
		errorMessages,
		errors,
		extraText,
		id,
		name,
		validate,
		addressee,
		value,
		step,
		type,
		register,
		increment,
		min: htmlInputProps.min,
		max: htmlInputProps.max,
		track,
		onChange,
		onBlur,
	});

	const Input = (
		<input
			{...htmlInputProps}
			type={
				revealPassword || hideIcon
					? "text"
					: type === "number"
						? "text"
						: type
			}
			id={id}
			step={step}
			autoComplete="off"
			placeholder={placeholder}
			className={c(Styles.input, {
				[Styles.inputError]: errors?.[name] || invalid,
				[Styles.inputDown]: labelType === "inner",
				[Styles.inputSmall]: hasNumberControls,
				[inputClassName]: !!inputClassName,
				"text-left": leftAlign,
			})}
			onChange={(e) => onChange && onChange(e.target.value)}
			onFocus={onFocus}
			{...(register &&
				register(name, {
					...validate,
					onBlur: onBlurCallback,
					...(onChange
						? { onChange: (event) => onChange(event.target.value) }
						: {}),
				}))}
			{...(!register && typeof value !== "undefined"
				? { value }
				: {})}
		/>
	);

	const TextArea = (
		<textarea
			id={id}
			autoComplete="off"
			placeholder={placeholder}
			className={c(Styles.textArea, {
				[Styles.inputError]: errors?.[name] || invalid,
				[Styles.inputDown]: labelType === "inner",
				[Styles.inputSmall]: hasNumberControls,
				[inputClassName]: !!inputClassName,
				"text-left": leftAlign,
			})}
			onChange={(e) => onChange && onChange(e.target.value)}
			onFocus={onFocus}
			{...(register &&
				register(name, {
					...validate,
					onBlur: onBlurCallback,
					...(onChange
						? { onChange: (event) => onChange(event.target.value) }
						: {}),
				}))}
			{...(!register && typeof value !== "undefined"
				? { value }
				: {})}
		></textarea>
	);

	return (
		<div
			className={c(Styles.InputWrapper, {
				...(className
					? { [className]: true }
					: {}),
			})}
			ref={wrapperRef}
		>
			<Label
				htmlFor={name}
				label={label}
				subLabel={subLabel}
				labelIsActive={!!(value || focussed)}
				labelType={labelType}
				className={labelClassName}
			/>

			{type === "number" && hasControls && (
				<InputQuantityControl
					onClick={onQuantityDecreaseClick}
					isDisabled={Boolean(
						validate?.min && value && parseFloat(`${value}`) <= validate.min
					)}
					icon="IconMinus"
				/>
			)}

			{beforeIcon
				? (
					<div className={c(Styles.beforeIcon)}>
						{beforeIcon}

						{type === "textarea"
							? TextArea
							: Input}
					</div>
				)
				: afterIcon
					? (
						<div className={c(Styles.afterIcon)}>
							{type === "textarea"
								? TextArea
								: Input}

							{afterIcon}
						</div>
					)
					: type === "password"
						? (
							<div className={Styles.passwordWrapper}>
								{Input}

								<span onClick={toggleShowHidePassword}>
									{revealPassword
										? "Hide"
										: "Show"}
								</span>
							</div>
						)
						: type === "search"
							? (
								<div className={Styles.searchWrapper}>
									<Icon icon={"Search"} />

									{Input}
								</div>
							)
							: (
								<>{type === "textarea"
									? TextArea
									: Input}</>
							)}

			{extraText && <span className={Styles.extraText}>{extraText}</span>}

			{type === "number" && hasControls && (
				<InputQuantityControl
					onClick={onQuantityIncreaseClick}
					isDisabled={Boolean(
						validate?.max && value && parseFloat(`${value}`) >= validate.max
					)}
					icon="IconPlus"
				/>
			)}

			{showErrors && errorMessage && (
				<div className={c(errorClassName, { [Styles.floatingError]: floatingErrors })}>
					{errorMessage}
				</div>
			)}
		</div>
	);
};
