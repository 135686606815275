import { useCallback } from "react"

import { signOut } from "next-auth/react";

import { useLoggedInCheckout } from "./useLoggedInCheckout";

import { useAccountDataStore, useUserDataStore } from "../store";

export const useLoginHelper = () => {
	const [updateUser] = useAccountDataStore((state) => [state.updateUser]);

	const resetCheckout = useUserDataStore((state) => state.resetCheckout);

	const { clearStoredCheckoutData } = useLoggedInCheckout();

	const logOut = useCallback((logoutUrl?: string, redirect: boolean = false) => {
		if (!window) {
			return;
		}

		signOut({
			callbackUrl: redirect
				? logoutUrl || `${window.location.origin}/login`
				: undefined,
			redirect,
		})
			.then(() => {
				updateUser(null);
				resetCheckout(null);
				clearStoredCheckoutData()
			});

	}, [
		clearStoredCheckoutData,
		resetCheckout,
		updateUser
	]);

	return {
		logOut
	}
}