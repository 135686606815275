import {
	useCallback, useEffect, useState 
} from "react";

import { useSession } from "next-auth/react";
import { useRouter } from "next/router";

import { useAccountDataStore } from "@/store/AccountDataStore";

import { useAccountEdit } from "./useAccountEdit";
import { useLoginHelper } from "./useLoginHelper";

import { getHealthReports } from "../API";

export const useAccountPage = (
	populateDeliveries = false,
	populateSuitableRecipes = false,
	populatePetHealthReports = false
) => {
	const { data, status } = useSession();
	const { populateLocalUser, getUserDeliveries, getUserSuitableRecipes } =
		useAccountEdit();
	const { push, query, pathname } = useRouter();
	const [isFetching, setIsFetching] = useState(false);

	const [
		setActivePet,
		setUser,
		user,
		setPetHealthReports,
		petHealthReports,
		userSuitableRecipes,
		userDeliveries,
	] = useAccountDataStore((state) => [
		state.setActivePet,
		state.setUser,
		state.user,
		state.setPetHealthReports,
		state.petHealthReports,
		state.userSuitableRecipes,
		state.userDeliveries,
	]);

	const { logOut } = useLoginHelper();

	useEffect(() => {
		if (status === "authenticated" && data && !data.user.token) {
		
			logOut();
			
			return;
		}

		if (user === null && status === "authenticated") {
			populateLocalUser();
		}
		
		if (
			populateDeliveries &&
			status === "authenticated" &&
			userDeliveries &&
			userDeliveries.length === 0
		) {
			getUserDeliveries();
		}

		if (
			populateSuitableRecipes &&
			status === "authenticated" &&
			userSuitableRecipes &&
			userSuitableRecipes?.length === 0
		) {
			getUserSuitableRecipes();
		}

		if (status === "unauthenticated") {
			push({ pathname: "/login",
				query: { referrer: pathname,
					...query } });
		}
	}, [
		data,
		getUserDeliveries,
		getUserSuitableRecipes,
		logOut,
		pathname,
		populateDeliveries,
		populateLocalUser,
		populateSuitableRecipes,
		push,
		query,
		setActivePet,
		setUser,
		status,
		user,
		userDeliveries,
		userDeliveries?.length,
		userSuitableRecipes,
		userSuitableRecipes?.length
	]);

	const populateHealthReports = useCallback(async () => {
		if (!data) {
			return;
		}

		setIsFetching(true);
		
		return await getHealthReports(data.user.id, true).then((data) => {
			setPetHealthReports(data);
			setIsFetching(false);
			
			return data.pets;
		});
	}, [data, setPetHealthReports]);

	useEffect(() => {
		if (
			populatePetHealthReports &&
			petHealthReports &&
			petHealthReports.length === 0 &&
			!isFetching
		) {
			populateHealthReports();
		}
	}, [
		isFetching,
		petHealthReports,
		populateHealthReports,
		populatePetHealthReports,
	]);

	return {
		populateHealthReports,
		isFetching,
	};
};
