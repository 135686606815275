import { useCallback } from "react";

import { useSession } from "next-auth/react";

import { useAccountDataStore } from "@/store/AccountDataStore";

import { useLoggedInCheckout } from "./useLoggedInCheckout";
import { useLoginHelper } from "./useLoginHelper";

import {
	addAddress,
	deleteAddress,
	fetchOrderTrackingInfo,
	getCustomerData,
	getCustomerDeliveries,
	getCustomerDelivery,
	getSuitableRecipes,
	mutateAddress,
	mutateCustomerInformation,
	mutateDelivery,
	mutateItems,
	mutatePet,
	mutateSubscription,
	processDelivery,
} from "../API";
import { CustomerPetAPIResponse } from "../types";

export const useAccountEdit = () => {
	const { data, status } = useSession();

	const { storeCheckoutData } = useLoggedInCheckout();

	const [
		user,
		updateUser,
		setUser,
		setActivePet,
		setIsLoading,
		setUserDeliveries,
		setUserSuitableRecipes,
		setUserDelivery,
		setDeliveriesLoading,
		setTrackingInfo,
	] = useAccountDataStore((state) => [
		state.user,
		state.updateUser,
		state.setUser,
		state.setActivePet,
		state.setIsLoading,
		state.setUserDeliveries,
		state.setUserSuitableRecipes,
		state.setUserDelivery,
		state.setDeliveriesLoading,
		state.setTrackingInfo,
	]);

	const { logOut } = useLoginHelper()

	const onError = useCallback(() => {
		logOut();
	}, [logOut]);

	const populateLocalUser = useCallback(
		async (setPetActive: boolean = true) => {
			if (!data || status !== "authenticated") {
				return;
			}

			setIsLoading(true);

			await getCustomerData(data.user.id, true, data.user.token, onError).then((info) => {
				if (info.error === "Token is missing") {
					return;
				}
				if (info.error || !info.data) {
					return;
				}

				const response = info.data;

				response.token = data.user.token;
				setUser(response);
				setPetActive && setActivePet(response.pets?.[0]?.id);

				const firstActivePet = response?.pets?.find(
					({ subscription: { active } }) => active
				);

				const addressIdInUse = firstActivePet?.subscription.new_address_id;
				const firstActiveAddress = response?.addresses?.find(
					({ id }) => addressIdInUse && `${id}` === `${addressIdInUse}`
				);

				storeCheckoutData({
					firstName: response.first_name,
					lastName: response.last_name,
					phoneNumber:
						response.phone !== null
							? response.phone?.replace("+44", "0") || ""
							: "",
					email: response.email,
					line1: firstActiveAddress?.line_1 || "",
					line2: firstActiveAddress?.line_2 || "",
					city: firstActiveAddress?.city || "",
					postcode: firstActiveAddress?.postcode || "",
					province: firstActiveAddress?.province || "",
					userId: data.user.id,
					parcelshop_id: "",
					contact_via_email: response.contact_via_email === "1",
				});
			})
				.catch(() => {
					logOut();
				});

			setIsLoading(false);
		},
		[
			data,
			logOut,
			onError,
			setActivePet,
			setIsLoading,
			setUser,
			status,
			storeCheckoutData
		]
	);

	const getSingleUserDeliveryRecord = useCallback(
		(deliveryId: number) => {
			if (!data) {
				return;
			}

			setIsLoading(true);

			getCustomerDelivery(deliveryId, true).then((delivery) => {
				setUserDelivery(delivery);

				setIsLoading(false);
			});
		},
		[
			data,
			setIsLoading,
			setUserDelivery
		]
	);

	const getUserDeliveries = useCallback(() => {
		if (!data || !data?.user?.id) {
			return;
		}

		setIsLoading(true);
		setDeliveriesLoading(true);

		getCustomerDeliveries(data.user.id, true, data.user.token, onError).then(({ data: deliveriesData, error }) => {
			if (error) {
				setIsLoading(false);
				setDeliveriesLoading(false);
			}

			if (deliveriesData){
				setUserDeliveries(deliveriesData);
			}

			setIsLoading(false);
			setDeliveriesLoading(false);
			setTrackingInfo(null);
		});
		
	}, [
		data,
		onError,
		setDeliveriesLoading,
		setIsLoading,
		setTrackingInfo,
		setUserDeliveries
	]);

	const getUserSuitableRecipes = useCallback(async () => {
		if (!data) {
			return;
		}
		setIsLoading(true);
		
		return await getSuitableRecipes(data.user.id, true, data.user.token, onError).then((data) => {

			if (!data.data) {
				return;
			}

			setUserSuitableRecipes(data.data);
			setIsLoading(false);
			
			return data.data.pets;
		});
	}, [
		data,
		onError,
		setIsLoading,
		setUserSuitableRecipes
	]);

	const updateCustomer = useCallback(
		async (updates: {
			first_name?: string;
			last_name?: string;
			phone?: string;
			email?: string;
			default_shipping_method?: string;
		}) => {
			if (status !== "authenticated" || !user) {
				return;
			}
			const newData = await mutateCustomerInformation(
				user?.shopify_id,
				data.user.id,
				updates
			);

			if (newData.data && !newData.error) {
				updateUser(newData.data);
			}

			return newData;
		},
		[
			data?.user.id,
			status,
			updateUser,
			user
		]
	);

	const deleteDeliveryAddress = useCallback(
		async (addressId: number) => {
			if (status !== "authenticated" || !user) {
				return false;
			}

			const response = await deleteAddress(user.id, addressId);

			if (response.error) {
				return false;
			}

			await populateLocalUser(false);

			return true;
		},
		[
			populateLocalUser,
			status,
			user
		]
	);

	const updateAddress = useCallback(
		async (
			address: {
				city: string;
				line_1: string;
				line_2: string;
				province: string;
				postcode: string;
			},
			customerId: number,
			addressId?: string
		) => {
			const result =
				addressId && customerId
					? await mutateAddress(customerId, addressId, address)
					: await addAddress(customerId, address);

			if (result.customerUserErrors) {
				return {
					error: result.customerUserErrors,
					data: null,
				};
			}

			await populateLocalUser(false);

			return {
				error: null,
				data: result.UpdateAddress,
			};
		},
		[populateLocalUser]
	);

	const updatePet = useCallback(
		async (
			updates: Record<string, unknown>,
			petId: number
		): Promise<{ data: CustomerPetAPIResponse } | false> => {
			if (status !== "authenticated" || !user) {
				return false;
			}
			const updated = await mutatePet(user?.shopify_id, petId, updates);

			if (!updated.error) {
				await populateLocalUser(false);
				
				return { data: updated };
			}

			return false;
		},
		[
			populateLocalUser,
			status,
			user
		]
	);

	const sendDeliveryNow = useCallback(
		async (deliveryId: number, dontSetErrorMessage = false) => {
			if (status !== "authenticated" || !user) {
				return false;
			}

			const updated = await processDelivery(
				user.id,
				deliveryId,
				dontSetErrorMessage
			);

			if (!updated.error) {
				await populateLocalUser(false);
				
				return {
					deliveryDate: updated.data.delivery_date,
				};
			}

			return false;
		},
		[
			populateLocalUser,
			status,
			user
		]
	);

	const updateDelivery = useCallback(
		async (
			data: Record<string, unknown>,
			deliveryId: number,
			clear_error = false
		) => {
			if (status !== "authenticated" || !user) {
				return false;
			}

			const updated = await mutateDelivery(
				data,
				user.shopify_id,
				deliveryId,
				clear_error
			);

			if (!updated.error) {
				await populateLocalUser(false);
				
				return true;
			}

			return false;
		},
		[
			populateLocalUser,
			status,
			user
		]
	);

	const updateSubscription = useCallback(
		async (data: Record<string, unknown>, subscriptionId: number) => {
			if (!user) {
				return {
					error: "An error occured, please try again.",
				};
			}

			const updated = await mutateSubscription(
				data,
				user.shopify_id,
				subscriptionId
			);

			if (!updated.error) {
				await populateLocalUser(false);
				
				return {
					success: true,
				};
			}

			return {
				error: updated.error,
			};
		},
		[populateLocalUser, user]
	);

	const updateItems = useCallback(
		async (data: any, subscriptionId: number, deliveryId?: number) => {
			if (!user || !data || data.length < 1) {
				return {
					error: "An error occurred. Please try again.",
				};
			}

			const updated = await mutateItems(
				data,
				user.shopify_id,
				subscriptionId,
				deliveryId
			);

			if (!updated.error) {
				await populateLocalUser(false);
				
				return {
					error: null,
				};
			}

			return {
				error: updated.error,
			};
		},
		[populateLocalUser, user]
	);

	const getDeliveryTrackingInfo = useCallback(async (deliveryId: string) => {
		return await fetchOrderTrackingInfo(deliveryId);
	}, []);

	return {
		updateCustomer,
		updatePet,
		updateDelivery,
		updateSubscription,
		populateLocalUser,
		updateItems,
		updateAddress,
		sendDeliveryNow,
		getUserDeliveries,
		getUserSuitableRecipes,
		deleteDeliveryAddress,
		getSingleUserDeliveryRecord,
		getDeliveryTrackingInfo,
	};
};
