import Link from "next/link";
import c from "classnames";

import Styles from "./mobileNavBar.module.scss";

import { Icon } from "../FormElements/Icon";
import { useAccountRaf } from "@/pagesComponents/AccountRaf/useAccountRaf";

export const MobileNavBar = ({ page }: { page: string }) => {
	// Temporary fix
	const { currentScheme } = useAccountRaf()

	return (
		<div className={Styles.mobileNavBar}>
			<ul>
				<li className={c({ [Styles.isActive]: page === "dashboard" })}>
					<Link href="/account/dashboard">
						<a>
							<Icon height="24px" width="24px" icon="Home" />
							<div>Dashboard</div>
						</a>
					</Link>
				</li>
				<li className={c({ [Styles.isActive]: page === "pet" })}>
					<Link href="/account/pet">
						<a>
							<Icon height="24" width="24" icon="Paw" />
							<div>Pets</div>
						</a>
					</Link>
				</li>
				<li className={c({ [Styles.isActive]: page === "deliveries" })}>
					<Link href="/account/deliveries">
						<a>
							<Icon height="24px" width="24px" icon="Calendar" />
							<div>Deliveries</div>
						</a>
					</Link>
				</li>
				<li className={c({ [Styles.isActive]: page === "shop" })}>
					<Link href="/account/shop">
						<a>
							<Icon height="24px" width="24px" icon="Shop" />
							<div>Shop</div>
						</a>
					</Link>
				</li>
				{currentScheme && <li className={c({ [Styles.isActive]: page === "raf" })}>
					<Link href="/account/raf">
						<a>
							<Icon height="24px" width="24px" icon="Pound" />
							<div>Get £{currentScheme.amount / 100}</div>
						</a>
					</Link>
				</li>}
			</ul>
		</div>
	);
};
