import {
	useCallback, useEffect, useMemo, useRef, useState
} from "react";

import ReactDOMServer from "react-dom/server";

import { useAccountPage } from "@/hooks/useAccountPage";
import { getReferralSchemes } from "@/API/account";
import { useAccountDataStore } from "@/store/AccountDataStore";
import { track } from "@/helpers/analytics";
import { RAFInformation, RafInformationReferralScheme } from "@/types/Account";
import { toSentence } from "@/helpers/helperFunctions";
import { useMediaQueries } from "@/hooks/useMediaQueries";

export const useAccountRaf = () => {
	useAccountPage();

	const [user] = useAccountDataStore((state) => [state.user]);

	const [schemes, setSchemes] = useState<
		RAFInformation["customer"]["referral"] | undefined
	>();
	const [currentScheme, setCurrentScheme] = useState<
		RafInformationReferralScheme | undefined
	>();
	const [isCopied, setIsCopied] = useState(false);

	const getSchemesTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
	const attempts = useRef(0);

	const { isDesktop } = useMediaQueries();

	useEffect(() => {
		const getSchemes = async () => {
			if (getSchemesTimeout.current !== null) {
				clearTimeout(getSchemesTimeout.current);
			}

			if (!user || attempts.current > 10) {
				return;
			}

			const customerSchemes = await getReferralSchemes(`${user?.id}`, false, `${user?.token}`);


			if (!customerSchemes?.customer?.referral) {
				attempts.current++;
				getSchemesTimeout.current = setTimeout(() => getSchemes(), 200);

				return;
			}
			setSchemes(customerSchemes.customer.referral);

			setCurrentScheme(
				customerSchemes.customer.referral?.schemes.filter((item: any) => {
					if (user.ambassador_set_at && item.slug === "ambassador") {
						return true;
					} else if (item.slug === "feb-22-20-50" && !user.ambassador_set_at) {
						return true;
					}

					return false;
				})[0]
			);
		};
		getSchemes();
	}, [user]);

	const copyLink = useCallback((text: string) => {
		track("RAF share", {
			category: "RAF",
			method: "copy",
		});
		navigator.clipboard.writeText("http://" + text);
		setIsCopied(true);
		setTimeout(() => {
			setIsCopied(false);
		}, 2000);
	}, []);

	const doShare = useCallback((method: string) => {
		track("RAF share", {
			category: "RAF",
			method: method,
		});
	}, []);

	const shareText = useMemo(
		() =>
			currentScheme
				? (
					<>
						Get{" "}

						{currentScheme?.discountCode?.type === "fixed"
							? "£" + (currentScheme.discountCode?.amount / 100).toFixed(0)
							: currentScheme.discountCode?.amount + "%"}{" "}
						off your first box

						{currentScheme?.discountCode?.future_discounts?.map((discount) => (
							<>
								{" "}

								and{" "}

								{discount?.type === "fixed"
									? "£" + (discount.amount / 100).toFixed(0)
									: discount?.amount + "%"}{" "}
								off your next
							</>
						))}{" "}
						delivery of delicious, wholesome, healthy dog food. Click the link to
						get a special discount, so your dog can start enjoying delicious food
						made for a long healthy life!
					</>
				)
				: (
					<></>
				),
		[currentScheme]
	);

	const shareSubject = useMemo(
		() =>
			currentScheme
				? (
					<>
						Your healthy dog food discount from{" "}

						{currentScheme.referralScheme.name}

						{currentScheme.referralScheme.pets.length > 0 && (
							<> and {toSentence(currentScheme.referralScheme.pets)}</>
						)}
					</>
				)
				: (
					<></>
				),
		[currentScheme]
	);

	const whatsappLink =
		"https://api.whatsapp.com/send?text=" +
		encodeURIComponent(ReactDOMServer.renderToStaticMarkup(shareText)) +
		" https://www.purepetfood.com/s/" +
		currentScheme?.referralScheme.token +
		"/w";

	const facebookLink =
		"https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fpurepetfood.com/s/" +
		currentScheme?.referralScheme.token +
		"/f&quote=" +
		encodeURIComponent(ReactDOMServer.renderToStaticMarkup(shareText));

	const emailLink =
		"mailto:?subject=" +
		encodeURIComponent(ReactDOMServer.renderToStaticMarkup(shareSubject)) +
		"&body=" +
		encodeURIComponent(ReactDOMServer.renderToStaticMarkup(shareText)) +
		" https://www.purepetfood.com/s/" +
		currentScheme?.referralScheme.token +
		"/e";

	const shareLink = () => {
		if (navigator.share) {
			track("RAF share", {
				category: "RAF",
				method: "share",
			});
			navigator
				.share({
					title: ReactDOMServer.renderToStaticMarkup(shareSubject),
					text: ReactDOMServer.renderToStaticMarkup(shareText),
					url:
						"https://www.purepetfood.com/s/" +
						currentScheme?.referralScheme.token +
						"/s",
				})
				.then(() => console.log("Successful share"))
				.catch((error) => console.log("Error sharing", error));
		}
	};

	return {
		copyLink,
		shareLink,
		shareText,
		whatsappLink,
		facebookLink,
		emailLink,
		doShare,
		currentScheme,
		isCopied,
		schemes,
		isDesktop,
	};
};
